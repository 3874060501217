<template>
  <div class="message">
      <div class="message-list" v-for="(item,index) in messageList" :key="index">  
          <div class="message-list-img">
              <img src="../../assets/img/small-bell.png" alt="">
              <i v-if="item.status == 0"></i>
          </div>
          <div class="message-text">
              <div><h3>{{item.title}}</h3><span>{{item.createTime}}</span></div>
              <p>{{item.message}}</p>
          </div>
      </div>

      <div class="none_bg" v-if="messageList.length == 0">
        <img src="../../assets/img/none_bg.png" alt="">
      </div>
  </div>
</template>

<script>
import { listMessage } from "../../api/personal"
export default {
    data(){
        return{
            messageList:[]
        }
    },
    created(){
        this.listMessage()
    },
    methods:{
        listMessage(){
            listMessage().then((res) => {
                if(res.data.code == 20000){
                    this.messageList = res.data.data.messageList
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.message{
    width:100%;
    height: 592px;
    background-color:#FFFFFF;
    overflow:auto;
    padding:30px;
    .message-list{
        display:flex;
        margin-bottom:30px;
        .message-list-img{
            width:36px;
            height:36px;
            border-radius:50%;
            background-color: #FF7100;
            display:flex;
            align-items:center;
            justify-content: center;
            position:relative;
            img{
                width:24px;
                height:24px;
            }
            i{
                width:8px;
                height:8px;
                background-color:#FF7100;
                border:1px solid #ffffff;
                border-radius: 50%;
                position: absolute;
                right:0;
                top:0;
            }
        }
        .message-text{
            margin-left:10px;
            div{
                height:36px;
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                h3{
                    color:#333333;
                    font-size:14px;
                }
                span{
                    color:#999999;
                    font-size:12px;
                }
            }
            p{
                color:#333333;
                font-size:16px;
                margin-top: 10px;
            }
        }
    }
    .none_bg{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        img{
            width:200px;
            height:200px;
        }
    }
}
</style>